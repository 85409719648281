<template>
  <div class="width_1920">
    <div class="top_lay">
      <img class="image1" src="../../assets/img/sports/show/Pic_Bg_DB.png" />
      <div class="text">小小运动会-{{ name }}</div>
    </div>
    <div v-if="projectList.length != 0" class="name_lay">
      <div class="w137 normal_text">名次</div>
      <div class="line_v"></div>
      <div class="flex normal_text">队伍名称</div>
      <div class="line_v"></div>
      <div
      
        v-if="projectList[0]"
        :class="
          projectList[0].status != 1
            ? 'flex normal_text'
            : 'flex current_text center'
        "
      >
        {{ projectList[0].title }}
      </div>
      <div
        v-if="projectList[1]"
        :class="
          projectList[1].status != 1
            ? 'flex normal_text'
            : 'flex current_text center'
        "
      >
        {{ projectList[1].title }}
      </div>
      <div
        v-if="projectList[2]"
        :class="
          projectList[2].status != 1
            ? 'flex normal_text'
            : 'flex current_text center'
        "
      >
        {{ projectList[2].title }}
      </div>
      <div
        v-if="projectList[3]"
        :class="
          projectList[3].status != 1
            ? 'flex normal_text'
            : 'flex current_text center'
        "
      >
        {{ projectList[3].title }}
      </div>
      <div
        v-if="projectList[4]"
        :class="
          projectList[4].status != 1
            ? 'flex normal_text'
            : 'flex current_text center'
        "
      >
        {{ projectList[4].title }}
      </div>
      <div
        v-if="projectList[5]"
        :class="
          projectList[5].status != 1
            ? 'flex normal_text'
            : 'flex current_text center'
        "
      >
        {{ projectList[5].title }}
      </div>
      <div
        v-if="projectList[6]"
        :class="
          projectList[6].status != 1
            ? 'flex normal_text'
            : 'flex current_text center'
        "
      >
        {{ projectList[6].title }}
      </div>
      <div
        v-if="projectList[7]"
        :class="
          projectList[7].status != 1
            ? 'flex normal_text'
            : 'flex current_text center'
        "
      >
        {{ projectList[7].title }}
      </div>
      <div class="line_v"></div>
      <div class="w147 normal_text">总分</div>
    </div>
    <div
      :class="index % 2 == 0 ? 'label' : 'label1'"
      v-for="(item, index) in sportslist"
      :key="index"
    >
      <div class="w137 rank_num center">0{{ item.rank }}</div>
      <div class="flex team-name center">{{ item.teamName }}</div>
      <div
        v-if="projectList[0]"
        :class="
          projectList[0].status != 1
            ? 'flex score1 center'
            : item.scores[0]
            ? 'flex score2 center'
            : 'flex score3 center'
        "
      >
        {{
          item.scores[0]
            ? item.scores[0]
            : projectList[0].status != 1
            ? "--"
            : "进行中"
        }}
      </div>
      <div
        v-if="projectList[1]"
        :class="
          projectList[1].status != 1
            ? 'flex score1 center'
            : item.scores[1]
            ? 'flex score2 center'
            : 'flex score3 center'
        "
      >
        {{
          projectList[1].status == 1 || projectList[1].status == 2
            ? item.scores[1]
              ? item.scores[1]
              : "进行中"
            : projectList[0].status == 1
            ? "下一项"
            : "--"
        }}
      </div>
      <div
        v-if="projectList[2]"
        :class="
          projectList[2].status != 1
            ? 'flex score1 center'
            : item.scores[2]
            ? 'flex score2 center'
            : 'flex score3 center'
        "
      >
        {{
          projectList[2].status == 1 || projectList[2].status == 2
            ? item.scores[2]
              ? item.scores[2]
              : "进行中"
            : projectList[1].status == 1
            ? "下一项"
            : "--"
        }}
      </div>
      <div
        v-if="projectList[3]"
        :class="
          projectList[3].status != 1
            ? 'flex score1 center'
            : item.scores[3]
            ? 'flex score2 center'
            : 'flex score3 center'
        "
      >
        {{
          projectList[3].status == 1 || projectList[3].status == 2
            ? item.scores[3]
              ? item.scores[3]
              : "进行中"
            : projectList[2].status == 1
            ? "下一项"
            : "--"
        }}
      </div>
      <div
        v-if="projectList[4]"
        :class="
          projectList[4].status != 1
            ? 'flex score1 center'
            : item.scores[4]
            ? 'flex score2 center'
            : 'flex score3 center'
        "
      >
        {{
          projectList[4].status == 1 || projectList[4].status == 2
            ? item.scores[4]
              ? item.scores[4]
              : "进行中"
            : projectList[3].status == 1
            ? "下一项"
            : "--"
        }}
      </div>
      <div
        v-if="projectList[5]"
        :class="
          projectList[5].status != 1
            ? 'flex score1 center'
            : item.scores[5]
            ? 'flex score2 center'
            : 'flex score3 center'
        "
      >
        {{
          projectList[5].status == 1 || projectList[5].status == 2
            ? item.scores[5]
              ? item.scores[5]
              : "进行中"
            : projectList[4].status == 1
            ? "下一项"
            : "--"
        }}
      </div>
      <div
        v-if="projectList[6]"
        :class="
          projectList[6].status != 1
            ? 'flex score1 center'
            : item.scores[6]
            ? 'flex score2 center'
            : 'flex score3 center'
        "
      >
        {{
          projectList[6].status == 1 || projectList[6].status == 2
            ? item.scores[6]
              ? item.scores[6]
              : "进行中"
            : projectList[5].status == 1
            ? "下一项"
            : "--"
        }}
      </div>
      <div
        v-if="projectList[7]"
        :class="
          projectList[7].status != 1
            ? 'flex score1 center'
            : item.scores[7]
            ? 'flex score2 center'
            : 'flex score3 center'
        "
      >
        {{
          projectList[7].status == 1 || projectList[7].status == 2
            ? item.scores[7]
              ? item.scores[7]
              : "进行中"
            : projectList[6].status == 1
            ? "下一项"
            : "--"
        }}
      </div>
      <div class="end_l center">{{ item.scores[projectList.length] }}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: "",
      sportslist: [],
      projectList: [],
      current: 0,
    };
  },
  mounted() {
    let that = this;
    that.getRankList();
    setInterval(() => {
      setTimeout(() => {
        that.getRankList();
      }, 0);
    }, 5000);
  },
  methods: {
    getRankList() {
      var that = this;
      let data = {};
      that.api.s_sports.getRankList(data).then((res) => {
        if (res.code == 200) {
          that.projectList = res.result.projects;
          that.sportslist = res.result.teamRank;
          that.name = res.result.stand.name;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.display-flex {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.width_1920 {
  width: 1920px;
  height: 1080px;
  display: flex;
  flex-direction: column;
}
.top_lay {
  position: relative;
  .image1 {
    width: 1920px;
    height: 130px;
  }
  .text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 130px;
    font-size: 64px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.name_lay {
  width: 1920px;
  height: 106px;
  background: #545454;
  display: flex;
  flex-direction: row;
  .rank_text {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffead1;
    opacity: 0.9;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.line_v {
  width: 1px;
  height: 106px;
  background: #666666;
}
.normal_text {
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.team-name {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  opacity: 0.9;
}
.score1 {
  font-size: 32px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #111111;
  opacity: 0.9;
}
.score2 {
  font-size: 40px;
  font-family: Impact;
  font-weight: 400;
  color: #ffa000;
  opacity: 0.9;
}
.score3 {
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffa000;
  opacity: 0.9;
}
.current_text {
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffa000;
  opacity: 0.9;
}
.label {
  width: 1920px;
  height: 103px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
}
.w137 {
  width: 137px;
}
.w147 {
  width: 147px;
}
.label1 {
  width: 1920px;
  height: 103px;
  background: #f7f7f7;
  display: flex;
  flex-direction: row;
}
.rank_num {
  font-size: 40px;
  font-family: Impact;
  font-weight: 400;
  color: #db310f;
  opacity: 0.9;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.end_l {
  width: 147px;
  height: 100%;
  font-size: 40px;
  font-family: Impact;
  font-weight: 400;
  color: #111111;
  opacity: 0.9;
}
.flex {
  flex: 1;
}
</style>
